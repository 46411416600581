import React, {Component,Fragment} from 'react';

class MainComponent extends Component{

  onClick(delta){

  }
  render(){
    return (
      <div>
        {"우리의 첫번쨰 웹페이지 입니다."}
      </div>
    );
  }

} 
export default MainComponent;
